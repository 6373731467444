import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { arSA, de, enUS, es, frCA, fr, pl, ptBR, he, hu } from 'date-fns/locale'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import axios from 'axios'

export const supportedLanguages = [
  { lng: 'ar', name: 'عربي', dateFnsLoc: arSA },
  { lng: 'en', name: 'English', dateFnsLoc: enUS },
  { lng: 'de', name: 'Deutsch', dateFnsLoc: de },
  { lng: 'fr', name: 'Français', dateFnsLoc: fr },
  { lng: 'fr-CA', name: 'Français (Canadien)', dateFnsLoc: frCA },
  { lng: 'he', name: 'עִברִית', dateFnsLoc: he },
  { lng: 'hu', name: 'magyar', dateFnsLoc: hu },
  { lng: 'pl', name: 'Polskie', dateFnsLoc: pl },
  { lng: 'pt-BR', name: 'Português (Brasil)', dateFnsLoc: ptBR },
  { lng: 'es', name: 'Español', dateFnsLoc: es },
  ...(process.env.NODE_ENV === 'development'
    ? [{ lng: 'cimode', name: 'Keys (dev)', dateFnsLoc: enUS }]
    : []),
]

export const supportedLanguagesForPubPages = [
  { lng: 'en', name: 'English', dateFnsLoc: enUS },
  { lng: 'de', name: 'Deutsch', dateFnsLoc: de },
  { lng: 'hu', name: 'magyar', dateFnsLoc: hu },
  { lng: 'pl', name: 'Polskie', dateFnsLoc: pl },
  ...(process.env.NODE_ENV === 'development'
    ? [{ lng: 'cimode', name: 'Keys (dev)', dateFnsLoc: enUS }]
    : []),
]

export const getCurrentLangObj = (lng) =>
  supportedLanguages.find((l) => l.lng === lng)

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    // debug: true,
    ns: ['pub'],
    defaultNS: ['priv'],
    interpolation: {
      escapeValue: false,
    },
    transSupportBasicHtmlNodes: true,
    
    supportedLngs: supportedLanguages.map(({ lng }) => lng),
    backend: {
      loadPath: `/mcr/translation/{{ns}}?lng={{lng}}`,
      crossDomain: true,
      withCredentials: true,
      request: async function (options, url, payload, callback) {
        try {
          const { data } = await axios.get(url)
          callback(null, { status: 'ok', data })
        } catch (e) {
          callback(e, null)
          // throw new Error('There was a problem loading the localization file.')
        }
      },
    },
  })

export default i18n
